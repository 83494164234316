import React from "react";
import RingLoader from "react-spinners/RingLoader";

const CustomerSpinner = () => {
  return (
    <div className="customerLoading">
      <div className="spinnerBox">
        <RingLoader size={100} color="#6b5ce7" radius="8" />
      </div>
    </div>
  );
};

export default CustomerSpinner;
