import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import CustomerSpinner from './pages/loading/CustomerSpinner.jsx';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from './ErrorFallback.jsx';
import './assets/styles/Main.css';
import './assets/styles/rainbowkit.css';

const TheLayout = React.lazy(() => import('./components/TheLayout.jsx'));

function App() {
    return (

                    <BrowserRouter>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <React.Suspense fallback={<CustomerSpinner/>}>
                                <Routes>
                                    <Route path="/*" name="Containers" element={<TheLayout/>}/>
                                </Routes>
                            </React.Suspense>
                        </ErrorBoundary>
                    </BrowserRouter>

    );
}

export default App;
